import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/feiten-over-diarree/"> Feiten over diarree </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips voor behandeling
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                  Oorzaken van diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/stress-en-diarree/">
                  Stress en diarree
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/stress-en-diarree/hoge-werklast/">
                      Hoge werkdruk
                    </a>
                  </li>
                  <li className="last">
                    <a href="/feiten-over-diarree/stress-en-diarree/diarree-examen/">
                      Examensituaties
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/feiten-over-diarree/buikgriep/">
                  Virale of bacteriële infectie
                </a>
              </li>
              <li className="active">
                <a href="/feiten-over-diarree/norovirus/">Norovirus</a>
              </li>
              <li>
                <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                  Diarree tijdens de menstruatie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/chronische-diarree/">
                  Chronische Diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/eenartsraadplegen/">
                  Een arts raadplegen?
                </a>
              </li>
              <li className="last">
                <a href="/feiten-over-diarree/gezonde-eetgewoonten/">
                  Gezonde eetgewoonten
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselallergie235n/">
                      Voedselallergieën
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselvergiftiging/">
                      Voedselvergiftiging
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Tips bij diarree</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Tips bij diarree"
                  />
                </div>
                <p>
                  Rond het behandelen van de symptomen van diarree bestaan er
                  nog vele misverstanden en&nbsp;mythes. Hier vind je enkele
                  tips over het behandelen van de symptomen van diarree.
                </p>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips bij diarree
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-29">
            <h1>Norovirus</h1>
            <div className="img">
              <img
                src="/assets/files/pages/buikgriep.jpeg"
                width="701"
                height="289"
                alt="Norovirus"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Het norovirus en diarree</h2>
              </div>
              <div className="nine columns">
                <p>
                  De zeer besmettelijke <strong>norovirussen</strong> zijn
                  verantwoordelijk voor het merendeel van de niet-bacteriële
                  gastro-enteritis. Ze verspreiden zich bijzonder snel waar veel
                  mensen in een nauwe ruimte samenkomen – bijvoorbeeld in
                  kleuterscholen, scholen, ziekenhuizen of bejaardentehuizen.
                </p>
                <p>
                  Van de besmetting tot de uitbraak van de infectie duurt het
                  over het algemeen enkele uren tot twee dagen.
                </p>
              </div>
            </div>
            <h3>Symptomen van het norovirus</h3>
            <p>
              De symptomen omvatten misselijkheid, hevig braken, buikkrampen en
              diarree. Vooral voor kinderen en bejaarde of verzwakte mensen kan
              het sterke vochtverlies erg gevaarlijk worden. In de meeste
              gevallen verdwijnen de symptomen echter na twee tot drie dagen en
              genees je zonder blijvende gevolgen.
            </p>
            <h3>Besmetting met het norovirus</h3>
            <p>
              Norovirussen kunnen van mens op mens, maar ook via voorwerpen
              overgedragen worden. Ze kunnen bijvoorbeeld overleven op
              voorwerpen als handdoeken, deurklinken of wc-brillen. Zelfs
              temperaturen tot +60 graden Celsius kunnen ze een aantal minuten
              verdragen. Nog tot twee dagen na de acute fase blijft de ziekte
              besmettelijk. In de ontlasting blijven de virussen zelfs tot een
              aantal weken na de infectie aantoonbaar.
            </p>
            <h3>Preventie norovirus</h3>
            <p>
              Er bestaat <strong>geen inenting tegen het norovirus</strong>. De
              enige bescherming tegen besmetting is een goede hygiëne:
            </p>
            <ul>
              <li>
                Handen wassen na elk wc-bezoek of voor het eten of bereiden van
                eten. Beschik je over 2 wc’s in huis, reserveer er dan eentje
                tijdelijk voor de zieke alleen.
              </li>
              <li>
                Voedsel zoals vruchten en sla moeten grondig gereinigd worden.
              </li>
              <li>Voedsel moet goed gaar gekookt worden.</li>
              <li>
                Wie ziek wordt door het norovirus, moet het contact met anderen
                enkele dagen (minstens twee dagen na het einde van de acute
                fase) beperken. Vermijd zeker contact met jonge kindjes of
                ouderen. Ook verzwakte personen ontzie je best even.
              </li>
              <li>
                Zieken moeten absoluut hun eigen handdoek en voorwerpen voor
                lichaamsverzorging gebruiken.
              </li>
              <li>
                Het bestek moet in de vaatwasser gewassen worden op het hoogste
                programma.
              </li>
              <li>
                Was beddenlakens, handdoeken en kleding van de zieken - zo
                mogelijk - bij minstens 60 graden Celsius.
              </li>
            </ul>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
